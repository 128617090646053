<script>
import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import tableData from "@/components/tablecomponent";
// import ChangeDate from "@/components/changeDateComponent";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
/**
 * Advanced-table component
 */

export default {
  page: {
    title: appConfig.prospectCustomer,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableData,
    // ChangeDate,
    btnComponent,
    Multiselect,
  },
  data() {
    return {
      title: appConfig.prospectCustomer,
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.prospectCustomer,
          active: true,
        },
      ],

      /*error: "please input data",
      Testpost: false,
      testpost:{
        name:"",
        job:"",
      },*/
      teamOption: [],
      teamSearch: "",
      optionStatus: [
        {
          status: "DFT",
          name: " ร่าง ",
        },
        {
          status: "SUBMIT",
          name: " รออนุมัติ ",
        },
        {
          status: "APV",
          name: " เสร็จสิ้น ",
        },
        {
          status: "CAN",
          name: " ยกเลิก ",
        },
      ],
      optionYear: [
        { id: "2022" },
        { id: "2021" },
        { id: "2020" },
        { id: "2019" },
      ],
      optionMonth: [
        { id: "01", name: "มกราคม" },
        { id: "02", name: "กุมภาพันธ์" },
        { id: "03", name: "มีนาคม " },
        { id: "04", name: "เมษายน" },
        { id: "05", name: "พฤษภาคม" },
        { id: "06", name: "มิถุนายน" },
        { id: "07", name: "กรกฎาคม" },
        { id: "08", name: "สิงหาคม" },
        { id: "09", name: "กันยายน" },
        { id: "10", name: "ตุลาคม" },
        { id: "11", name: "พฤศจิกายน" },
        { id: "12", name: "ธันวาคม" },
      ],
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      userId: "",
      filter: {
        ppCusCode: "",
        nameTh: "",
        branchId: "",
        familyNameTh: "",
        cusSrcId: "",
        occId: "",
        status: "",
        salary: "",
        teamId: "",
        userId: "",
      },
      cusSrcOption: [],
      paramURL: "",
      submitformUser: false,
      selectAll: false,
      selectCCs: [],
      statusLabel: "",
      localDataBranch: [],
      localData: [],
      overlayFlag: false,
      loading: false,
      total_pages: "",
      page: "",
      per_page: "",
      total: "",
      rowData: [], //? Data ที่ต่อ API
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      // filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      selectedUsers: [],
      isSelectedAll: false,
      selectMode: "multi",
      selected: [],

      occOption: [],
      statusOption: [
        { nameTh: "กำลังติดตาม", id: "A" },
        { nameTh: "เสร็จสิ้น", id: "C" },
        { nameTh: "Lost", id: "L" },
      ],
      url: "https://dms.suriyait.com/",
      qrCode: "",
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      userOption: [],
      status: "",
      DateSearch: "",
      returnCode: "",
      branchIdSearch: "",
      cusNameSearch: "",
      cusFamilyNameSearch: "",
      vehicleSearch: "",
      monthSearch: "",
      yearSearch: "",
      bookingCode: "",
      qnCode: "",

      // packageId,

      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "ppCusCode",
          label: "รหัสลูกค้ามุ่งหวัง",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "ชื่อ",
          sortable: true,
        },
        {
          key: "familyNameTh",
          label: "นามสกุล",
          sortable: true,
        },
        {
          key: "cusSrcNameTh",
          label: "แหล่งที่มา",
          sortable: true,
        },

        {
          key: "occNameTh",
          label: "อาชีพ",
          sortable: true,
        },
        {
          key: "level",
          label: "ความสนใจ",
          sortable: true,
        },
        {
          key: "salary",
          label: "รายได้",
          sortable: true,
        },
        {
          key: "createdBy",
          label: "ที่ปรึกษาการขาย",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          sortable: true,
        },
        {
          key: "branchNameTh",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },
  validations: {
    userId: { required },
  },
  computed: {
    /**
     * Total no. of records
     */
    //
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.rowData.length;
  },
  watch: {
    selectCCs(newValue) {
      if (newValue.length !== this.rowData.length) {
        this.selectAll = false;
      } else if (newValue.length === this.rowData.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
  },
  created() {
    this.getLocalData();
    this.getData();
    this.getCustomerSource();
    this.getOccupations();
    this.getUser();
    this.getTeam();
  },
  methods: {
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName != null ? familyName : ""}`;
    },
    print(Data) {
      this.data = Data;
      this.$router.push({
        name: "add-contactJob",
        params: {
          data: this.data.item,
        },
      });
    },
    selectAlls() {
      this.selectCCs = [];
      if (!this.selectAll) {
        for (let i in this.rowData) {
          this.selectCCs.push(this.rowData[i].ppCusId);
        }
      }
    },
    showQr() {
      useNetw
        .post("api/prospect-customer/gen-qr", {})
        .then((response) => {
          // this.showAlert(response.data.message);
          this.qrCode = response.data.qrCode;
          var str = this.qrCode;
          var n = str.split("?");
          var res = "";
          for (var i = 0; i < n.length; i++) {
            res = n[i];
          }
          // const local = "http://localhost:3000/";
          this.paramURL = `${this.url}public-pp-cus-form?${res}`;
          this.$refs["qr"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranchIdUser = localDataBranch;
      this.branchId = user.branchId;
      // // console.log(this.localDataBranch);
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchIdUser;
      } else {
        this.localDataBranchIdUser = [
          this.branchIdSearch ? this.branchIdSearch.branchId : this.branchId,
        ];
      }
      this.getData();
      //
    },
    getSearch() {
      this.getCustomerSource();
      this.getOccupations();
      this.getUser();
      this.getTeam();
    },
    valistoreC() {
      this.$v.$touch();
      this.submitformUser = true;
      if (this.$v.$invalid != true) {
        this.valistoreCC();
      }
    },
    valistoreCC() {
      if (this.selectCCs.length >= 1) {
        this.storeCC();
      } else {
        Swal.fire(
          appConfig.swal.title.error,
          "กรุณาเลือกรายการส่งติดต่อ",
          appConfig.swal.type.error
        );
      }
    },
    storeCC: function () {
      this.overlayFlag = true;
      useNetw
        .post("api/prospect-customer/store-contact-job", {
          ppCusId: this.selectCCs,
          userId: this.userId.userId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          this.selectCCs = [];
          this.submitformUser = false;
          this.userId = "";
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            // err.message +
            //   "<br>" +
            // err.response.data.message +
            // "<br>" +
            err.response.data.validationMessage[0],
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getData: function () {
      this.loading = true;
      useNetw
        .get("api/prospect-customer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchIdUser,
            ppCusCode: this.filter.ppCusCode,
            nameTh: this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
            cusSrcId: this.filter.cusSrcId ? this.filter.cusSrcId.cusSrcId : "",
            occId: this.filter.occId ? this.filter.occId.occId : "",
            status: this.filter.status ? this.filter.status.id : "",
            salary: this.filter.salary ? this.filter.salary.id : "",
            teamId: this.filter.teamId ? this.filter.teamId.teamId : "",
            userId: this.filter.userId ? this.filter.userId.userId : "",
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowData = response.data.data; //?ข้อมูล
           const per_Page  = response.data.perPage; // ?จำนวนข้อมูลที่แสดงต่อหน้า
          this.page = response.data.page; // ?หน้าที่เท่าไหร่
          this.total = response.data.total; //? จำนวนข้อมูลทั้งหมด
          this.total_pages = Math.ceil(this.total / per_Page);

          this.statusLabel = response.data.statusLabel.status;
          this.salaryLabel = response.data.statusLabel.salary;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getCustomerSource: function (event) {
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-customer-source", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdSearch
              ? this.branchIdSearch.branchId
              : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.cusSrcOption = response.data.data;
          // this.perPage = response.data.perPage;
          // this.from = response.data.from;
          // this.totalRecord = response.data.total;
          // this.to = response.data.to;
          // this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getOccupations: function (event) {
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-occupation", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdSearch
              ? this.branchIdSearch.branchId
              : this.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.occOption = response.data.data;
          // this.perPage = response.data.perPage;
          // this.from = response.data.from;
          // this.totalRecord = response.data.total;
          // this.to = response.data.to;
          // this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getTeam: function (event) {
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/dealer-team", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdSearch
              ? this.branchIdSearch.branchId
              : this.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.teamOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getUser: function (event) {
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/dealer-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.branchIdSearch
              ? this.branchIdSearch.branchId
              : this.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.userOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },

    deleteParts: function (rowData) {
      this.partData = rowData;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/prospect-customer/delete", {
          data: {
            ppCusId: this.partData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getData();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-md-center mt-4">
              <div class="col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                        @input="getSearch()"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2 text-end">
                      รหัสลูกค้ามุ่งหวัง:
                      <b-form-input
                        v-model="filter.ppCusCode"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      ชื่อ:
                      <b-form-input
                        v-model="filter.nameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-sm-4 col-md-3">
                    <label class="d-inline align-items-center m-2">
                      นามสกุล:
                      <b-form-input
                        v-model="filter.familyNameTh"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </label>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> แหล่งที่มาของลูกค้า:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.cusSrcId"
                        :options="cusSrcOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                        @search-change="getCustomerSource($event)"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="occId"> อาชีพ:</label>
                      <multiselect
                        id="occId"
                        v-model="filter.occId"
                        :options="occOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                        @search-change="getOccupations($event)"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="salary"> รายได้:</label>
                      <multiselect
                        id="salary"
                        v-model="filter.salary"
                        :options="salaryOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                      ></multiselect>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="occId"> สถานะ:</label>
                      <multiselect
                        id="occId"
                        v-model="filter.status"
                        :options="statusOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="nameTh"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> ทีม:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.teamId"
                        :options="teamOption"
                        :show-labels="false"
                        label="name"
                        track-by="teamId"
                        @search-change="getTeam($event)"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="cusSrcId"> ที่ปรึกษาการขาย:</label>
                      <multiselect
                        id="cusSrcId"
                        v-model="filter.userId"
                        :options="userOption"
                        :show-labels="false"
                        label="nameTh"
                        track-by="userId"
                        :custom-label="customLabel"
                        @search-change="getUser($event)"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >
                    </div>
                  </div>
                  <div class="col-12 text-end">
                    <b-button variant="danger" @click="showQr">QR Code</b-button
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;

                    <router-link
                      :to="{ name: 'add-prospectCustomer' }"
                      class="btn btn-primary"
                    >
                      <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูล
                    </router-link>
                  </div>
                  <!-- <div class="row float-end"></div> -->
                </div>
              </div>
            </div>

            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-5 col-md-6 col-xxl-8">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="handlePageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-7 col-md-6 col-xxl-4">
                    <div class="row">
                      <div class="col-sm-7 col-md-8 col-xxl-8">
                        <div class="mb-3 position-relative">
                          <code>* </code>
                          <label
                            for="cusSrcId"
                            class="d-inline align-items-center"
                          >
                            ผู้รับผิดชอบ:</label
                          >
                          <multiselect
                            id="cusSrcId"
                            v-model="userId"
                            :options="userOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getUser($event)"
                            :class="{
                              'is-invalid': submitformUser && $v.userId.$error,
                            }"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                          <div
                            v-if="submitformUser && $v.userId.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.userId.required">{{ error }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-xxl-4 col-md-4 col-sm-5 text-end">
                        <div class="mb-3 position-relative">
                          <br />
                          <b-button
                            @click="valistoreC()"
                            variant="primary"
                            class="mb-2"
                            >ส่งติดต่อ</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End search -->
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>
              <tableData :fields="fields" :items="rowData">
                <template
                  v-slot:head(select)="rowData"
                  v-if="rowData.length != 0"
                >
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @click="selectAlls"
                    class="form-check-input"
                  />
                  {{ rowData.label }}
                </template>
                <template #cell(select)="rowData" v-if="rowData.length != 0">
                  <input
                    type="checkbox"
                    :value="rowData.item.ppCusId"
                    v-model="selectCCs"
                    class="form-check-input"
                  />
                </template>

                <template #cell(index)="rowData" v-if="this.currentPage > 1">
                  {{ rowData.index + 1 + (currentPage * perPage - perPage) }}
                </template>
                <template #cell(index)="rowData" v-else>
                  {{ rowData.index + 1 }}
                </template>
                <template #cell(level)="rowData">
                  <span v-if="rowData.item.level == '3'"> สูง </span>
                  <span v-if="rowData.item.level == '2'"> กลาง </span>
                  <span v-if="rowData.item.level == '1'"> ต่ำ </span>
                  <span v-if="rowData.item.level == '0'"> อื่นๆ </span>
                </template>

                <template #cell(salary)="rowData">
                  <span v-if="rowData.item.salary == '4'">
                    100,000 ขึ้นไป"
                  </span>
                  <span v-if="rowData.item.salary == '3'">
                    50,001 - 100,000
                  </span>
                  <span v-if="rowData.item.salary == '2'">
                    30,001 - 50,000
                  </span>
                  <span v-if="rowData.item.salary == '1'">
                    10,001 - 30,000
                  </span>
                  <span v-if="rowData.item.salary == '0'">
                    ต่ำกว่า 10,000
                  </span>
                  <span v-else-if="rowData.item.salary == null"> ไม่ระบุ </span>
                </template>
                <template #cell(status)="rowData">
                  <span
                    class="badge bg-warning font-size-12 ms-2"
                    v-if="rowData.item.status === 'A'"
                  >
                    {{ statusLabel.A }}
                  </span>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    v-if="rowData.item.status === 'C'"
                  >
                    {{ statusLabel.C }}
                  </span>
                  <span
                    class="badge bg-danger font-size-12 ms-2"
                    v-if="rowData.item.status === 'L'"
                  >
                    Lost
                  </span>
                </template>
                <template #cell(action)="rowData">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        :to="{
                          name: 'edit-prospectCustomer',
                          params: { ppCusId: rowData.item.ppCusIdBase64 },
                        }"
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </router-link>

                      <a
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="alert(rowData.item.ppCusId)"
                      >
                        <i class="uil uil-trash-alt font-size-18"></i> </a
                      >&nbsp;
                      <b-button
                        @click="print(rowData)"
                        size="sm"
                        variant="primary"
                        >สร้างการติดต่อ</b-button
                      >
                    </li>
                  </ul>
                </template>
              </tableData>
              <br />
              <div class="row">
                <span
                  v-if="this.rowData.length === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-else>
                  <div class="align-items-center">
                    หน้า {{ currentPage }} จาก {{ this.total_pages }} ทั้งหมด
                    {{ this.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
            <b-modal
              ref="qr"
              id="qr"
              centered
              hide-header
              hide-footer
              title="BootstrapVue"
            >
              <div class="row justify-content-md-center">
                <vue-qr :text="this.paramURL" :size="150"></vue-qr>
                <p class="text-center"><code>แบบฟอร์มมีอายุ 1 ชั่วโมง</code></p>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
